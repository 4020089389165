import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getImageSrc } from '../../../utils';

import { createLoadOrder } from '../../../actions/order';
import { createSelectFeaturedShopItemPopup } from '../../../actions/popup';

import Header from '../Header';
import Footer from '../Footer';
import { usePublicViewEntityContext } from '../../../context/PublicViewEntityProvider';
import { usePublicViewTemplateDataContext } from '../../../context/PublicViewTemplateDataProvider';

function getStateFromProps(props) {
  const template_data = props.template_data;
  const title = (template_data.title || { value: props.title }).value;
  const button_text = (template_data.button_text || { value: 'Browse all of our Products' }).value;
  return {
    title,
    button_text
  };
}

function FeaturedProduct({
  index,
  isEditable,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    entityId,
    entityOrder: gallery,
    currencySymbol,
    entityIsFree,
    baseEntityUrl,
  } = usePublicViewEntityContext();
  const {
    templateId,
    template_data,
    templateColor: template_color,
  } = usePublicViewTemplateDataContext();

  function onFeatureProduct(index) {
    dispatch(createSelectFeaturedShopItemPopup(
      entityId, templateId, `featured-product-${index}`
    )); // todo update for not just shop_id
  }

  const handleClickProduct = (index) => {
    return e => {
      e.preventDefault();
      if (isEditable) {
        onFeatureProduct(index);
      } else {
        const item_id = (template_data[`featured-product-${index}`] || {}).value;
        if (item_id) {
          navigate(`${baseEntityUrl}/${item_id}`);
        }
      }
    };
  };

  const item = gallery.items.filter(i => i.item_id === (template_data[`featured-product-${index}`] || {}).value)[0];
  const url = item ? getImageSrc(item.images[0], 'large') : '';
  const currency_symbol = currencySymbol || '$';
  let item_prices = [];
  if (item && item.options && item.options.length) {
    item_prices = item.options.filter(o => 0 == o.hidden && 0 != o.unit_price).map(o => parseFloat(o.unit_price));
  }else if(item && item.min_price && item.max_price) {
    item_prices = [item.min_price, item.max_price];
  }

  const minimum_price = Math.min.apply(null, item_prices);
  const maximum_price = Math.max.apply(null, item_prices);
  const displayPrice = item_prices.length ? (minimum_price === maximum_price ? `${currency_symbol}${minimum_price.toFixed(2)}` : `${currency_symbol}${minimum_price.toFixed(2)} - ${currency_symbol}${maximum_price.toFixed(2)}`) : null;

  return (
    <div className="small-12 medium-3 columns">
      <div id="feat-prod" onClick={handleClickProduct(index)}>
        <div className="prod-img" style={{ backgroundImage: `url('${url}')`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' , textAlign: 'center' }}>
          {!url && isEditable && <a className="button secondary" style={{ position: 'relative', top: '50%', tranform: 'translateY(-50%)' }}>Choose a featured product</a>}
        </div>
        <h4>{item && item.item_name}</h4>
       {+entityIsFree !== 1 && <p style={{ color: template_color }}>{displayPrice}</p>
        }
      </div>
    </div>
  );
}

function TitleImage({ title }) {
  const { template_data } = usePublicViewTemplateDataContext();
  const background_url = template_data.background?.value || '/images/template1-bg.jpg';
  return (
    <div className="shop-hdr" style={{ backgroundImage: `url('${background_url}')` }}>
      <h1 style={{ height: '225px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', textShadow: 'rgb(0, 0, 0) 0px 2px 15px' }}>{title}</h1>
    </div>
  );
}

const DefaultTemplate = ({
  isEditable,
  hideIntro = false,
  isPreviewing = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { baseEntityUrl, title, entityOrder, entityBuyInventory, entityType } = usePublicViewEntityContext();
  const { template_data, templateColor, } = usePublicViewTemplateDataContext();

  const [state, setState] = useState({
    editing: false,
    ...getStateFromProps({ title, template_data }),
  });

  const onUpdateField = (field, value) => setState(s => ({ ...s, [field]: value }));

  const loadOrder = useCallback((order_id) => {
    dispatch(createLoadOrder(order_id));
  }, []);

  useEffect(() => {
    if (!entityOrder.loaded) {
      loadOrder(entityOrder.order_id);
    }
  }, [loadOrder, entityOrder.order_id, entityOrder.loaded]);

  useEffect(() => {
    setState((s) => ({
      ...s, ...getStateFromProps({ title, template_data })
    }));
  }, [template_data, title]);

  const handleClickShop = (e) => {
    e.preventDefault();
    if (isEditable) {
      e.stopPropagation();
      onUpdateField('editing', 'button_text');
    } else {
      navigate(baseEntityUrl);
    }
  };

  return (
    <div id="home">
      <Header
        isEditable={isEditable}
        hideIntro={hideIntro}
        isPreviewing={isPreviewing}
        buy_inventory={entityBuyInventory}
      />
      <div id="template1">
        <TitleImage title={state.title} />
        <div className="row">
          <div className="large-12 columns hdr">
            <h2>Featured Products</h2>
          </div>
          <FeaturedProduct index={0} isEditable={isEditable} />
          <FeaturedProduct index={1} isEditable={isEditable} />
          <FeaturedProduct index={2} isEditable={isEditable} />
          <FeaturedProduct index={3} isEditable={isEditable} />
        </div>
        <div className="row">
          <div className="small-12 columns">
            <a id="footer-btn" style={{ color: templateColor }} onClick={handleClickShop}>
              {state.button_text}
            </a>
          </div>
        </div>
        <Footer isEditable={isEditable} style={entityType === 'ORDER' ? { position: 'unset' } : {}} />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTemplate);
